// import { Link } from "gatsby"
import React from "react"
import { Link } from "gatsby"
import tw from 'tailwind.macro';
import styled from 'styled-components';
const version = require('../../../../package.json').version;
export default (props) => {

  return (
    <PageFooter>
      <First>
        © 2018-2019 urbanlink.nl<br/>
        v{version} - <Link to="/roadmap">Roadmap</Link>
      </First>

      {/* <Second>
        <Menu>
          <MenuItem>About</MenuItem>
          <MenuItem>FAQ</MenuItem>
          <MenuItem>Contact</MenuItem>
          <MenuItem>Privacybeleid</MenuItem>
        </Menu>
      </Second> */}
    </PageFooter>
  )
}

const PageFooter = styled.header`
  ${ tw`px-4 py-4 text-gray-600 flex text-sm` }
  border-top: 1px solid #efefef;
`
const First = styled.div`${tw`w-1/3 leading-loose`}`
// const Second = styled.div`${tw`w-2/3 text-right`}`
// const Menu = styled.ul`${tw`leading-loose`}`
// const MenuItem = styled.li`${tw`list-none`}`
