import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from './header/Header';
import Footer from './footer';
import { createGlobalStyle } from "styled-components"
import Normalize from 'react-normalize'
import styled from 'styled-components'
import tw from 'tailwind.macro'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;
    h1, h2, h3,h4, h5, h6 {
      font-family: 'Roboto Slab'
    }
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  return (
    <React.Fragment>
      <Normalize />
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Main>{children}</Main>
      <Footer />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const Main = styled.main`${tw`p-4`}`

export default Layout
